

/* root: {
    flexGrow: 1,
    backgroundColor:"#F2F3F5",
    "& .MuiInputBase-input": {
        height: "1.5em",
        backgroundColor:"#fff"
      }
    // padding: theme.spacing(2),
} */
/* .root{ 
width: 42;
height: 26;
padding: 0;
margin: 8px
} */

.mainheader {
    padding:0px 0px 0px 7px
}
.fontWeightHeader {
    font-weight:800
}
.commonmargin{
  margin-top:20px;
}
.paperstyle {
    display: flex;
    flex-wrap: wrap;
}
.papercontent {
    padding:15px 15px 30px 15px
}
.fontWeightPrimary{
    font-weight:600;
    margin-bottom:15px
}
.submissionstat{
  font-weight:800; 
  font-size:1.5rem;
  color:#A5259F; 
  text-align:center;
}
.submissionstatlabel{
  font-weight:800;  
  font-size:1rem;
  text-align:center;
}
table{
  min-width: 650px;
}

.astrick {
  color: #BC2bb5;
}

.componentWrapper {
  padding: 0 20px 20px;
  width: 100%;
}
.formHeading{
  font-size: 16px;
  font-weight: 600;
}

.formHeader {
  margin-top: 0;
}
.formInner {
  background: white;
  border-radius: 10px;
  padding: 15px;
  margin-top: 15px;
}
.flagRow {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 400;
  box-shadow: none !important;
  background: #F8F8F8 !important;
  margin: 10px 0;
}

.flagImageWrapper img{
  height: 30px;
  width: 30px;
  padding: 5px;
}

.flagImageWrapper{
  padding: 10px;
}

.searchImage img {
  padding: 5px;
}

.flagDivider {
    height: 28px !important;
    align-self: center !important;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.12);
}

.flagLabel {
  margin-left: 1;
  flex: 1;
  font-weight: 700 !important;
  font-size: 17px !important;
}

.disableButton {
  color: #421D59;
  padding: 10px;
  text-decoration: underline;
  font-size: 15px;
  background: transparent;
  outline: 0;
  border:0;
  cursor:pointer !important;
}

.deleteButton {
  color: #AC36A6;
  padding: 10px;
  text-decoration: underline;
  font-size: 15px;
  background: transparent;
  outline: 0;
  border:0;
  cursor:pointer !important;
}

.saveButton {
  color: #fff;
  width: 232px;
  border: 1px solid #a5259f;
  height: 52px;
  background-color: #a5259f;
  margin: 25px 0 0 0;
}

.addButton {
  background-color: #fff;
  width: 200px;
  border: 1px solid #a5259f !important;
  height: 35px;
  color: #a5259f !important;
  margin: 15px 0 0 0 !important;
}

.draftText {
  color: #a5259f !important;
  font-weight: 600 !important;
}