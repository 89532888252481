/* Container needed to position the button. Adjust the width as needed */


.container {
    position: relative;
    width: 100%;
}

.container_album {
    position: relative;
    width: 100%;
    border: 1px solid gray;
    height: 200px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.container_album:hover {
    position: relative;
    width: 100%;
    border: 1px solid gray;
    background-color: #888;
    height: 200px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.overlay {
    background: rgba(0,0,0,.2);
    z-index: 5;
    position: relative;
}

/* Make the image responsive */
.container img {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
}

/* Style the button and place it in the middle of the container/image */
.container .btn {
    position: absolute;
    top: 15%;
    right: 0%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #888;
    opacity: 0.7;
    color: white;
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.container_album .btn {
    position: absolute;
    top: 15%;
    right: 0%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #888;
    opacity: 0.7;
    color: white;
    padding: 5px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.container_album .album_title{
    position: absolute;
    top: 15%;
    left: 15%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #999;
    opacity: 0.8;
    color: purple;
    font-weight: bold;
    padding: 7px 7px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.container_album .photos_count{
    position: absolute;
    bottom: 0%;
    left: 15%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #999;
    opacity: 0.8;
    color: purple;
    font-weight: bold;
    padding: 7px 7px;
    border: none;
    cursor: pointer;
    margin-left: 5px;
    border-radius: 5px;
}

.container .btn:hover {
    background-color: white;
}

.featureBox{
    height: 30px;
    width: 100%;
    position: absolute;
    bottom: 0;
    color: white;
    background-color: rgba(0, 0, 0, .4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.featureBoxAlbum{
    height: 30px;
    width: 100%;
    position: absolute;
    bottom: 0;
    color: white;
    background-color: rgba(0, 0, 0, .4);
    display: flex;
    padding-left: 15px;
    align-items: center;
}

.loaderBox{
    height: 200px;
    width: 100%;
    background-color: rgba(0, 0, 0, .4);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.dragMainBox{
    background-color: white;
    padding: 10px;
    margin: 6px;
}

.dragBox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    height: 40px;
}

.modalStyle{
    display: flex;
    align-items: center;
    justify-content: center;
}

.createAlbum{
    background-color: white;
    box-shadow: black;
    padding: 20px;
}

.reviewHeading{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.shareIcon{
    border: 1px solid #9a0f9a;
    height: 50px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 18px;
}
