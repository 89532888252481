:root {
    --primary: #37a9e1;
    --primary-gradient: linear-gradient(45deg, #153c7e 0%, #37a9e1 100%);
}
.section-header {
    text-align: center;
}
.section-header h2 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 16px;
}
.section-header hr {
    border: 1px solid #fff;
    width: 56px;
}
.bg-gradient_solid {
    background: var(--primary-gradient);
    color: #fff;
    padding: 48px 0;
}
.steps {
    position: relative;
    margin-top: 32px;
}
.steps::after {
    content: "";
    position: absolute;
    width: 1px;
    background-color: #6c1167;
    opacity: 0.4;
    top: 31px;
    bottom: 43px;
    left: 20px;
}

.steps .content p {
    color: #676767;
    font-size: 16px;
}

.steps .content h2 {
    font-weight: 600;
    font-size: 16px;
    color: #676767;
}

.steps-container {
    position: relative;
    background-color: inherit;
    left: -60px;
    flex-direction: row-reverse;
    width: auto;
    margin-bottom: 16px;
}

.steps-container .content {
    padding: 10px;
    background-color: white;
    position: relative;
    border-radius: 10px;
    width: 300px;
    min-width: 300px;
    box-shadow: 0px 16px 27px rgb(0 11 30 / 10%);
}

.steps .steps-container:first-child , .steps .steps-container:last-child  {
    /*left: 45px;*/
}

.steps .steps-container:first-child .step-line, .steps .steps-container:last-child .step-line{
    width: 53px;
    margin-left: -15px;
}

.steps-container {
    display: flex;
}

.steps .steps-container .dot {
    color: #6c1167;
    margin-top: 17px;
    margin-left: 20px;
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50%;
    flex-shrink: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 777;
}

.steps .steps-container_current:not(.steps-container_current ~ .steps-container_current) .dot {
    color: white;
    margin-top: 17px;
    margin-left: 20px;
    width: 25px;
    height: 25px;
    background-color:  #6c1167;
    border-radius: 50%;
    flex-shrink: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 777;
}

.secondGrid{
    margin-left: 20px !important;
}

.step-line {
    width: 40px;
    background-color: #6c1167;
    height: 1px;
    margin-top: 31px;
    opacity: 0.4;
    flex-shrink: 0;
}

.steps .steps-container .date_header {
    font-weight: bold;
    color: gray;
}

.steps .steps-container .details {
    color: gray;
    margin-bottom: 10px;
}

.steps .steps-container_current:not(.steps-container_current ~ .steps-container_current) .date_header {
    font-weight: bold;
    color: #6c1167;
}


.steps .steps-container_current:not(.steps-container_current ~ .steps-container_current) .details {
    color: #6c1167;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1024px) {
    .steps::after {
        content: "";
        position: absolute;
        width: 1px;
        background-color: #6c1167;
        opacity: 0.4;
        top: 31px;
        bottom: 43px;
        left: 10px;
    }

    .steps-container {
        position: relative;
        background-color: inherit;
        left: 30px;
        flex-direction: row-reverse;
        width: auto;
        margin-bottom: 16px;
    }

    .steps-container .content {
        padding: 10px;
        background-color: white;
        position: relative;
        border-radius: 10px;
        width: 150px;
        box-shadow: 0px 16px 27px rgb(0 11 30 / 10%);
    }
}

@media only screen and (max-width: 768px) {
    .steps::after {
        content: "";
        position: absolute;
        width: 1px;
        background-color: #6c1167;
        opacity: 0.4;
        top: 31px;
        bottom: 43px;
        left: 38px;
    }

    .steps-container {
        position: relative;
        background-color: inherit;
        left: -50px;
        flex-direction: row-reverse;
        width: auto;
        margin-bottom: 16px;
    }

    .steps-container .content {
        padding: 10px;
        background-color: white;
        position: relative;
        border-radius: 10px;
        width: 80%;
        box-shadow: 0px 16px 27px rgb(0 11 30 / 10%);
    }

    .secondGrid{
        margin-left: 0px !important;
    }
}