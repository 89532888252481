
.routes{
    color:white;
    display: block;
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    text-decoration: none;
}

.active_route{
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: purple;
    transition: width 0.3s ease;
}